
import { Helmet } from 'react-helmet';
import './App.css';

function App() {
  return (
    <div className="App">
       <Helmet>
        <meta name="apple-itunes-app" content="app-id=1562635950" />
      </Helmet>
      
      <header className="App-header">
        
        <p>
          Oded Software Solutions
        </p>
        
      </header>
    </div>
  );
}

export default App;
